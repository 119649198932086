body {

  background-image: url("../public/img/background_educopoils31.jpg");
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;

}

.main {

  background-color: rgba(255, 255, 255, 80%);
  margin: 20px 20px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  display: block;

}